<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>{{ this.name }}</h1>
          <ul class="icons">
            <li v-if="langcode == 'en'">{{ $t('Address') }}: Shop 103, G/F, Hoi Po Mansion, Riviera Gardens, No. 1 Yi
              Hong Street, Tsuen Wan, New Territories, Hong Kong
            </li>
            <li v-if="langcode == 'zh'">{{ $t('Address') }}: 新界荃灣怡康街 1 號海濱花園 1 座海寶閣地下103 號舖</li>
            <li>{{ $t('Email') }}: meatmekitchenco@outlook.com</li>
            <li>{{ $t('Tel') }}: 852-84814909</li>
          </ul>
          <a href="#" @click="viewAboutUs">
            {{ $t('About Us') }}
          </a>
          <a href="#" @click="viewProducts">
            {{ $t('Our Products') }}
          </a>
          <a href="#" @click="viewFeatures">
            {{ $t('Product Features') }}
          </a>
        </header>

        <!-- Footer -->
        <footer id="footer">
          <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
          <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
          <ul class="copyright">
            <li>&copy; MEAT ME KITCHEN</li>
          </ul>
        </footer>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('About Us') }}</h1>
          <div class="description" v-if="langcode == 'en'">
            {{ this.descEn }}
          </div>
          <div class="description" v-if="langcode == 'zh'">
            {{ this.descZh }}
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="our-products">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('Our Products') }}</h1>
          <div class="description" v-if="langcode == 'zh'">
            推薦產品：來自Dapoq Touche 的 Easy Paste Bahan Kisar
            <ul>
              <li>Cili Kering 500g</li>
              <li>Bawang Merah 500g</li>
              <li>Bawang Putih</li>
            </ul>
            訂購請Whatsapp: 2386 3330 ! 還可以得知更多的資料喔。
          </div>
          <div class="description" v-if="langcode == 'en'">
            Recommand Product: Easy Paste Bahan Kisar by Dapoq Touche
            <ul>
              <li>Cili Kering 500g</li>
              <li>Bawang Merah 500g</li>
              <li>Bawang Putih</li>
              To order, please Whatsapp: 2386 3330 ! You can also get more product info
            </ul>
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="product-features">
      <div id="main-4">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('Product Features') }}</h1>
          <div class="description" v-if="langcode == 'zh'">
            方便烹飪的自製醬料。
            下單後，我們會為您準備新鮮的醬料... 👌👌👌
            <ul>
              <li>清洗乾淨，品質有保障</li>
              <li>使用純水製作</li>
              <li>以愛心製作</li>
              <li>以清真認證</li>
            </ul>
          </div>
          <div class="description" v-if="langcode == 'en'">
            Homemade paste for your convinience cooking.
            Place your order, we will prepare fresh for you... 👌👌👌
            <ul>
              <li>wash clean for quality care</li>
              <li>using purifier water</li>
              <li>made with love</li>
              <li>halal</li>
            </ul>
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/04.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted() {
    const langcode = Cookies.get('langcode');
    if (langcode) {
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to MEAT ME KITCHEN. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'question_text': 'Your business hour is?',
          'answer': "Our Business Hour is 10:00am - 17:00pm.",
        },
        {
          'question': 'place_order',
          'question_text': 'Your business hour is?',
          'answer': 'If you have any questions about us, please call 852-69981318 for further follow-up after receiving the goods.',
        },
        {
          'question': 'product_source',
          'question_text': 'Where your product come from?',
          'answer': "Our product come from different place, such as Hong Kong, USA or Japan.",
        },
        {
          'question': 'order_instruction',
          'question_text': 'How to orders?',
          'answer': "If you want to order product from us, please feel free to contact us with our phone or email address.",
        },
        {
          'question': 'product_list',
          'question_text': 'Please give me the product list.',
          'answer': "Currently, we do not had an online product list. If had any question about it, please contact with us.",
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods: {
    viewAboutUs() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewProducts() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'block';
    },
    viewFeatures() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'none';
      const features = document.querySelector('.product-features');
      features.style.display = 'block';
    },
    backIndex() {
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'none';
      const features = document.querySelector('.product-features');
      features.style.display = 'none';
    },
    async loadConfig() {
      return new Promise(async (resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if (status !== 200 && status !== 401) {
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if (status === 401) {
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent() {
      try {
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      } catch (err) {
        console.log(err);
      }
    },
    selectLang(langcode) {
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction) {
      let question = userAction['value'];
      if(!question){
        question = userAction['text'];
      }
      let answer = this.replyTexts.find(function (item) {
        if(item.question === question){
          return true;
        }
        if(item.question_text === question){
          return true;
        }
      });
      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if (answer) {
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      } else {
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'Sorry, I could not understand your question. Maybe you want to ask:',
          disableInput: false,
          options: [
            {
              text: 'Where your product come from ?',
              value: 'product_source',
              action: 'postback' // Request to API
            },
            {
              text: 'Your business hour is?',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: 'How could I view the product list?',
              value: 'product_list',
              action: 'postback' // Request to API
            },
            {
              text: 'Ordering instructions',
              value: 'order_instruction',
              action: 'postback' // Request to API
            },
            {
              text: 'Contact Method',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
    langcode: state => state.langcode,
  }),
}
</script>

<style lang="scss">
h1 {
  text-align: left;
}

body.is-preload-1 #main {
  opacity: 1;
  right: 0;
}

.qkb-board {
  padding-bottom: 30px;
  width: 500px;
}

#viewer .slide .image {
  @media screen and (min-width: 1300px) {
    background-size: 100%;
  }
}

#header {
  a {
    display: inline-block;
    margin-right: 10px;
  }
}

.about-us,
.our-products,
.product-features {
  display: none;
}

.description {
  text-align: left;
  margin-bottom: 20px;
}
</style>
